var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsCard',{staticClass:"vs-flex-1",staticStyle:{"min-width":"230px"},attrs:{"padding":"small","aspect":_vm.aspect === 'primary' ? 'black' : 'white'}},[_c('div',{staticClass:"vs-colored-header-container vs-relative"},[(_vm.aspect === 'primary')?_c('VsBadge',{staticClass:"vs-absolute vs-right-0",attrs:{"aspect":_vm.color.replace('-500', '')}},[_vm._v(" "+_vm._s(_vm.$t("shop.planShopModal.top"))+" ")]):_vm._e(),_c('div',{staticClass:"vs-rounded-small vs-mb-5",class:_vm.headerColorClasses})],1),_c('div',[_c('div',{staticClass:"vs-h400 vs-text-grey-900",class:{
                'vs-text-white': _vm.aspect === 'primary'
            }},[_c('span',[_vm._v(" "+_vm._s(_vm.$t((_vm.translatePath + ".planCards." + _vm.planSlug + ".title")))+" ")])]),_c('div',{staticClass:"vs-body-small",class:{
                'vs-text-white': _vm.aspect === 'primary'
            },style:(_vm.variant === 'custom' ? '' : 'min-height: 80px;')},[_vm._v(" "+_vm._s(_vm.$t((_vm.translatePath + ".planCards." + _vm.planSlug + ".subtitle")))+" ")])]),_c('div',[_c('div',{staticClass:"vs-h700 vs-my-4",class:{
                'vs-text-white': _vm.aspect === 'primary'
            }},[(_vm.variant === 'custom')?_c('span',[_vm._v(" "+_vm._s(_vm.$t((_vm.translatePath + ".custom")))+" ")]):_c('div',[(!_vm.selectedPrice)?_c('span',[_vm._v("-")]):_vm._e(),(_vm.selectedPrice)?_c('span',[_vm._v(_vm._s(_vm._f("formatCurrencyNoZero")(_vm.selectedPrice.value)))]):_vm._e(),(_vm.selectedPrice)?_c('span',{staticClass:"vs-body-medium"},[_vm._v("/ "+_vm._s(_vm.$tc(("general.rate." + (_vm.selectedPrice.rate.type)), 1)))]):_vm._e()])]),(_vm.variant === 'contact' || _vm.variant === 'custom' || _vm.isOldSubscription)?_c('VsButton',{attrs:{"expand":_vm.variant !== 'custom'},on:{"click":function($event){return _vm.$emit('click-contact-button')}}},[_vm._v(" "+_vm._s(_vm.$t((_vm.translatePath + ".contact")))+" ")]):(_vm.selectedPrice && (_vm.selectedPrice._id === _vm.userPlanId))?_c('VsButton',{attrs:{"expand":"","to":{
                name: 'myPlan',
            },"aspect":"secondary"}},[_vm._v(" "+_vm._s(_vm.$t("shop.planShopModal.editPlan"))+" ")]):_c('VsButton',{attrs:{"disabled":!_vm.selectedPrice,"loading":_vm.loading,"expand":""},on:{"click":function($event){return _vm.purchase(false)}}},[_vm._v(" "+_vm._s(_vm.$t((_vm.translatePath + ".purchase")))+" ")]),(_vm.trialAvailable && _vm.variant !== 'custom' && (!_vm.shopUser || !_vm.shopUser.subscription) && !_vm.isOldSubscription)?_c('VsButton',{staticClass:"vs-mt-4",attrs:{"aspect":_vm.aspect === 'primary' ? 'inverse' : 'secondary',"loading":_vm.loadingTrial,"expand":""},on:{"click":function($event){return _vm.purchase(true)}}},[_vm._v(" "+_vm._s(_vm.$t("shop.planShopModal.freeTry"))+" ")]):_vm._e()],1),(_vm.variant !== 'custom')?_c('div',{staticClass:"vs-text-grey-900",class:{
            'vs-text-white': _vm.aspect === 'primary'
        }},[_c('div',{staticClass:"vs-h200 vs-mt-6 vs-mb-3"},[_vm._v(" "+_vm._s(_vm.$t((_vm.translatePath + ".planList." + _vm.planSlug + ".title")))+" ")]),_c('VsCheckList',{attrs:{"list":_vm.$t((_vm.translatePath + ".planList." + _vm.planSlug + ".list"))}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }