

















































































































import { Component, Vue } from 'vue-property-decorator'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSubscriptionCardMini from '@/modules/shop/components/VsSubscriptionCardMini/Index.vue'
import { formatNumber } from '@/utils/formatter'

@Component({
    name: 'VsComparePlansModal',
    components: {
        VsContainer,
        VsFullTopBarLayout,
        VsSubscriptionCardMini,
    },
})
export default class extends Vue {
    private show = false

    get sections () {
        return [
            {
                name: 'Gestione contatti',
                rows: [
                    {
                        name: 'Limite contatti',
                        communication: 'Illimitato',
                        acquisition: 'Illimitato',
                        professional: 'Illimitato',
                    },
                    {
                        name: 'Mittenti invio',
                        communication: '1',
                        acquisition: '3',
                        professional: '10',
                    },
                    {
                        name: 'Limite invio giornaliero',
                        communication: 'Illimitato',
                        acquisition: 'Illimitato',
                        professional: 'Illimitato',
                    },
                    {
                        name: 'Liste CRM',
                        communication: false,
                        acquisition: false,
                        professional: true,
                    },
                    {
                        name: 'Liste standard',
                        communication: formatNumber(2),
                        acquisition: formatNumber(10),
                        professional: formatNumber(20),
                    },
                    {
                        name: 'Campi di lista',
                        communication: formatNumber(5),
                        acquisition: formatNumber(10),
                        professional: formatNumber(50),
                    },
                    {
                        name: 'Etichette',
                        communication: false,
                        acquisition: true,
                        professional: true,
                    },
                    {
                        name: 'Etichette comportamentali su campagne',
                        communication: false,
                        acquisition: false,
                        professional: true,
                    },
                    {
                        name: 'Profilazione contatti',
                        communication: false,
                        acquisition: true,
                        professional: true,
                    },
                    {
                        name: 'GDPR compliant e registro consensi',
                        communication: true,
                        acquisition: true,
                        professional: true,
                    },
                ],
            },
            {
                name: 'Strumenti di creazione email',
                rows: [
                    {
                        name: 'Editor Drag&Drop',
                        communication: true,
                        acquisition: true,
                        professional: true,
                    },
                    {
                        name: 'Modelli email',
                        communication: 'Premium',
                        acquisition: 'Premium',
                        professional: 'Premium',
                    },
                    {
                        name: 'Spam test',
                        communication: true,
                        acquisition: true,
                        professional: true,
                    },
                    {
                        name: 'Spazio libreria immagini e file',
                        communication: formatNumber(10) + ' MB',
                        acquisition: formatNumber(50) + ' MB',
                        professional: formatNumber(100) + ' MB',
                    },
                    {
                        name: 'Senza Logo 4Dem nelle Email',
                        communication: 'Opzionale',
                        acquisition: true,
                        professional: true,
                    },
                ],
            },
            {
                name: 'Strumenti di invio Email',
                rows: [
                    {
                        name: 'Email Booster',
                        communication: false,
                        acquisition: true,
                        professional: true,
                    },
                    {
                        name: 'Invio multi-lista',
                        communication: false,
                        acquisition: true,
                        professional: true,
                    },
                    {
                        name: 'A/B test campaign',
                        communication: false,
                        acquisition: false,
                        professional: true,
                    },
                    {
                        name: 'Email transazionali',
                        communication: 'a crediti',
                        acquisition: 'a crediti',
                        professional: 'a crediti',
                    },
                    {
                        name: 'SMS',
                        communication: 'a crediti',
                        acquisition: 'a crediti',
                        professional: 'a crediti',
                    },
                ],
            },
            {
                name: 'Strumenti di analisi',
                rows: [
                    {
                        name: 'Esportazione contatti',
                        communication: false,
                        acquisition: true,
                        professional: true,
                    },
                    {
                        name: 'Statistiche di Lista avanzate',
                        communication: false,
                        acquisition: true,
                        professional: true,
                    },
                    {
                        name: 'Statistiche di campagna avanzate',
                        communication: false,
                        acquisition: true,
                        professional: true,
                    },
                    {
                        name: 'Statistiche esportabili (CSV, PDF)',
                        communication: false,
                        acquisition: true,
                        professional: true,
                    },
                    {
                        name: 'Download report di invio',
                        communication: false,
                        acquisition: true,
                        professional: true,
                    },
                    {
                        name: 'Conservazione dati sui contatti',
                        communication: '60gg',
                        acquisition: '180gg',
                        professional: '365gg',
                    },
                ],
            },
            {
                name: 'Campagne di marketing',
                rows: [
                    {
                        name: 'Form e PopUp',
                        communication: false,
                        acquisition: '5',
                        professional: '10',
                    },
                    {
                        name: 'Landing Page',
                        communication: false,
                        acquisition: '5',
                        professional: '10',
                    },
                    {
                        name: 'Statistiche avanzate Landing Page',
                        communication: false,
                        acquisition: true,
                        professional: true,
                    },
                    {
                        name: 'Flussi di marketing automation',
                        communication: false,
                        acquisition: '5',
                        professional: '10',
                    },
                    {
                        name: 'Trigger di automazione',
                        communication: false,
                        acquisition: 'Tutti',
                        professional: 'Tutti',
                    },
                ],
            },
            {
                name: 'Assistenza e supporto',
                rows: [
                    {
                        name: 'Assistenza STANDARD',
                        communication: true,
                        acquisition: true,
                        professional: true,
                    },
                    {
                        name: 'Assistenza via chat',
                        communication: 'Opzionale',
                        acquisition: 'Opzionale',
                        professional: true,
                    },
                    {
                        name: 'Onboarding personalizzato',
                        communication: false,
                        acquisition: false,
                        professional: true,
                    },
                    {
                        name: 'Coaching personale',
                        communication: false,
                        acquisition: false,
                        professional: true,
                    },
                ],
            },
            {
                name: 'Integrazioni',
                rows: [
                    {
                        name: 'API',
                        communication: true,
                        acquisition: true,
                        professional: true,
                    },
                    {
                        name: 'Integrazione e-commerce',
                        communication: 'Opzionale',
                        acquisition: 'Opzionale',
                        professional: true,
                    },
                    {
                        name: 'Integrazione WhatsApp®',
                        communication: '299€ una tantum',
                        acquisition: '299€ una tantum',
                        professional: '299€ una tantum',
                    },
                    {
                        name: 'Custom domain per Landing Page',
                        communication: false,
                        acquisition: false,
                        professional: true,
                    },
                    {
                        name: 'Premium Services',
                        communication: false,
                        acquisition: '50€ di coupon da spendere sui servizi di consulenza',
                        professional: '200€ di coupon da spendere sui servizi di consulenza',
                    },
                    {
                        name: 'Sconti su ulteriori servizi e consulenza 4DEM non cumulabile col coupon',
                        communication: false,
                        acquisition: false,
                        professional: '30%',
                    },
                ],
            },
        ]
    }

    public async openModal () {
        this.show = true
    }

    public closeModal () {
        this.show = false
    }
}
