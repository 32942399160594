
































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsCheckList from '@/components/VsCheckList/Index.vue'
import { formatEmailCreditsRateFromMetadata } from '@/utils/shop'
import { UserModule } from '@/store/modules/user'
import { isOldSubscription } from '@/utils/rules'

@Component({
    name: 'VsSubscriptionCard',
    components: {
        VsCheckList,
    },
})
export default class extends Vue {
    @Prop() selectedPrice!: any
    @Prop() aspect!: string
    @Prop() variant!: string
    @Prop({ default: 'custom' }) planSlug!: string
    @Prop({ default: 'white' }) color!: string
    @Prop({ default: 'shop.planShopModal' }) translatePath!: string
    @Prop({ default: '' }) userPlanId!: string

    private loading = false
    private loadingTrial = false

    get planList () {
        return `vs-${this.planSlug}-list`
    }

    get headerColorClasses () {
        const color = this.aspect === 'primary' ? 'white' : this.color
        return {
            ['vs-bg-' + color]: true,
            'vs-colored-header': true,
        }
    }

    get shopUser () {
        return UserModule.shopUser
    }

    get user () {
        return UserModule.user
    }

    get isOldSubscription () {
        return isOldSubscription(this.user.configuration.rules)
    }

    get trialAvailable () {
        return this.shopUser?.trialAvailable
    }

    private formatRate (metadata: any) {
        return formatEmailCreditsRateFromMetadata(metadata)
    }

    private purchase (trial: boolean) {
        this.loadingTrial = trial
        this.loading = !trial
        this.$emit('click-purchase-button', {
            priceId: this.selectedPrice && this.selectedPrice._id,
            trial,
        })
    }
}
