



































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsSubscriptionCardMini',
})
export default class extends Vue {
    @Prop() aspect!: string
    @Prop({ default: 'custom' }) planSlug!: string
    @Prop({ default: 'white' }) color!: string
    @Prop({ default: 'shop.planShopModal' }) translatePath!: string

    get headerColorClasses () {
        const color = this.aspect === 'primary' ? 'white' : this.color
        return {
            ['vs-bg-' + color]: true,
            'vs-colored-header': true,
        }
    }
}
